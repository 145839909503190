import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import app from "../utils/Firebase";

const Auth = (props) => {
	const [signinCheck, setSigninCheck] = useState(false);
	const [signedIn, setSignedIn] = useState(false);
	const location = useLocation();

	useEffect(() => {

		//ログインしてるかどうかチェック
		const auth = getAuth(app);
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setSignedIn(true);
				setSigninCheck(true);
			} else {
				setSignedIn(false);
				setSigninCheck(true);
			}
		})
	}, []);
	
	return !signinCheck ? (
		<div>Loading ...</div>
	) : (
		signedIn ? (
			<Outlet />
		) : (
			<Navigate to="/login" state={{ from: location }} />
		)
	)
};
export default Auth;