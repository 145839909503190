import React from "react";
import { Outlet } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

import "./styles.scss";

const Layout = () => {

	return (	
		<div className="layout_container">
			<Header />
			<div className="content">
				<Outlet />
			</div>
			<Footer />
		</div>
	)
};
export default Layout;