import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, query, where, startAfter, limit, orderBy, doc, updateDoc } from 'firebase/firestore/lite';

import app from "../../utils/Firebase";

import './styles.scss'

const Top = () => {
	const [items, setItems] = useState([]);
	const [lastItem, setLastItem] = useState(null);
	const [allLoaded, setAllLoaded] = useState(false);
	const [filterStatus, setFilterStatus] = useState("all");
	const db = getFirestore(app);
	const getLimit = 20;
	const orderByParam = "create_at";
	const karutaDbName = "karuta";

	const changeFilter = (e) => {
		setFilterStatus(e.target.value);
		setItems([]);
		setAllLoaded(false);
	}

	const changeKarutaStatus = (karutaId, status) => {
		updateDoc(doc(db, karutaDbName, karutaId), {
			status: status
		}).then( () => {
			if (filterStatus === "show") {
				const q = query(
					collection(db, karutaDbName), 
					where("status", "==", "show"),
					orderBy(orderByParam, "desc"), 
					limit(items.length)
				);
				refresh(q);
			} else if (filterStatus === "hide") {
				const q = query(
					collection(db, karutaDbName), 
					where("status", "==", "hide"),
					orderBy(orderByParam, "desc"), 
					limit(items.length)
				);
				refresh(q);
			} else {
				const q = query(
					collection(db, karutaDbName), 
					orderBy(orderByParam, "desc"), 
					limit(items.length)
				);
				refresh(q);
			}
		}).catch(err => window.alert(err));
	};

	const getNextPage = () => {
		if (filterStatus === "show") {
			const q = query(
				collection(db, karutaDbName), 
				where("status", "==", "show"),
				orderBy(orderByParam, "desc"), 
				startAfter(lastItem), 
				limit(getLimit)
			);
			fetch(q);
		} else if (filterStatus === "hide") {
			const q = query(
				collection(db, karutaDbName), 
				where("status", "==", "hide"),
				orderBy(orderByParam, "desc"), 
				startAfter(lastItem), 
				limit(getLimit)
			);
			fetch(q);
		} else {
			const q = query(
				collection(db, karutaDbName), 
				orderBy(orderByParam, "desc"), 
				startAfter(lastItem), 
				limit(getLimit)
			);
			fetch(q);
		}
	}

	useEffect(() => {
		if (filterStatus === "show") {
			const q = query(
				collection(db, karutaDbName), 
				where("status", "==", "show"),
				orderBy(orderByParam, "desc"),  
				limit(getLimit)
			);
			fetch(q);

		} else if (filterStatus === "hide") {
			const q = query(
				collection(db, karutaDbName), 
				where("status", "==", "hide"),
				orderBy(orderByParam, "desc"),  
				limit(getLimit)
			);
			fetch(q);

		} else {
			const q = query(
				collection(db, karutaDbName), 
				orderBy(orderByParam, "desc"),  
				limit(getLimit)
			);
			fetch(q);
		}
	}, [filterStatus]);

	const refresh = (q) => {
		getDocs(q)
			.then(querySnapshot => {
				let itemsTmp = [];
				querySnapshot.forEach((doc) => {
					itemsTmp.push({
						id: doc.id,
						data: doc.data()
					});
				});
				setItems([...itemsTmp]);
				setLastItem(querySnapshot.docs[querySnapshot.docs.length - 1]);
			}).catch(err => window.alert(err));
	};

	const fetch = (q) => {
		if (!allLoaded){
			getDocs(q)
				.then(querySnapshot => {
					console.log("ok")
					if (querySnapshot.docs.length !== getLimit){
						setAllLoaded(true);
					}
					let itemsTmp = [];
					querySnapshot.forEach((doc) => {
						itemsTmp.push({
							id: doc.id,
							data: doc.data()
						});
					});
					setItems([...items, ...itemsTmp]);
					setLastItem(querySnapshot.docs[querySnapshot.docs.length - 1]);
				}).catch(err => window.alert(err));
		}
	}

	return (
		<div className="top_container">
			<div className="title">
				カルタ一覧
			</div>
			<div>
				<label className="radio_button">
					<input name="status" type="radio" value="all" onChange={changeFilter} checked={filterStatus === "all"}/>
					全て
				</label>
				<label className="radio_button">
					<input name="status" type="radio" value="show" onChange={changeFilter} checked={filterStatus === "show"}/>
					公開中
				</label>
				<label className="radio_button">
					<input name="status" type="radio" value="hide" onChange={changeFilter} checked={filterStatus === "hide"}/>
					非公開
				</label>
			</div>
			<div className="karuta_items_container">
				{items.length !== 0 &&
					items.map((item, index) => (
						<div key={index} className="karuta_item">
							<img src={"https://firebasestorage.googleapis.com/v0/b/hicard-f4447.appspot.com/o/images%2Fkaruta%2F" + item.id + ".png?alt=media"} alt="karuta" />
							{/* <div className="karuta_id">{item.id}</div> */}
							<div className="karuta_info_container">
								<div className="text">やりたいこと：{item.data.text}</div>
								<div className="name">名前：{item.data.name}</div>
								<div className="create_at">作成日時：{item.data.create_at.toDate().toLocaleString()}</div>
								<div className="status">
									{item.data.hasOwnProperty("status") ? (
										item.data.status === "show" ? (
											<div className="show">公開中</div>
										) : (
											<div className="hide">非公開</div>
										)
									) : (
										<div class="show">公開中</div>
									)}
								</div>
								<button className="status_button" onClick={() => changeKarutaStatus(item.id, item.data.hasOwnProperty("status") ? (item.data.status==="show" ? "hide" : "show") : "hide")}>ステータスを切り替える</button>
							</div>
						</div>
					))
				}
				{allLoaded ? (
					<div className="bottom_text">no more karuta</div>
				) : (
					<div className="bottom_text load" onClick={getNextPage}>more load</div>
				)}
			</div>
		</div>
	);
};

export default Top;