import React from "react";
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut, sendPasswordResetEmail } from "firebase/auth";

import app from "../../../utils/Firebase";

import "./styles.scss";


const Header = () => {
	const navigate = useNavigate();

	const logout = () => {
		if (window.confirm("ログアウトしログイン画面へ戻りますか？")) {
			const auth = getAuth(app);
			signOut(auth);
			navigate("/login");
		}
	}

	const sendChangePasswordMail = () => {
		if (window.confirm("パスワード変更を行うメールを送信しますか？")) {
			const auth = getAuth();
			const user = auth.currentUser;
			let email = null;
			if (user !== null) {
				user.providerData.forEach((profile) => {
					email = profile.email;
				});
			}

			sendPasswordResetEmail(auth, email)
				.then(() => {
					signOut(auth);
					navigate("/login");
				})
				.catch((error) => {
					window.alert("メールの送信に失敗しました");
				});
		}
	}

	return (	
		<div className="header">
			<div className="title">やりたいことカルタ2022管理画面</div>
			<div className="menu">
				<div onClick={sendChangePasswordMail} className="menu_item">パスワード変更</div>
				<div onClick={logout} className="menu_item">ログアウト</div>
			</div>
		</div>
	)
};
export default Header;