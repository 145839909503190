import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import app from "../../utils/Firebase";

import './styles.scss'




const Login = () => {

	let elements = {};
	const navigate = useNavigate();

	const FIELDS = {
		id: "id",
		password: "password",
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();

		let id = elements[FIELDS.id].value;
		let password = elements[FIELDS.password].value;

		const auth = getAuth(app);
		signInWithEmailAndPassword(auth, id, password)
			.then((userCredential) => {
				navigate("/");
			})
			.catch((error) => {
				alert(error);
			});
	};

	return (
		<div className="login_container">
			<h1 className="login_title">
				ログイン
			</h1>
			<div className="login_form_container">
				<form
					onSubmit={handleFormSubmit}
					ref={(el) => (elements = el && el.elements)}
				>
					<table>
						<tbody>
							<tr>
								<th>
									<label htmlFor="login_id">ID</label>
								</th>
								<td>
									<input
										id="login_id"
										type="text"
										name={FIELDS.id}
										style={{ width: "100%" }}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<label htmlFor="login_password">PASSWORD</label>
								</th>
								<td>
									<input
										id="login_password"
										type="password"
										name={FIELDS.password}
										style={{ width: "100%" }}
									/>
								</td>
							</tr>
						</tbody>
					</table>
					<input
						type="submit"
						value="ログイン"
						className="submit_button"
					/>
				</form>
			</div>
		</div>
	);
};

export default Login