import "./styles.scss";

const Footer = () => {

	return (	
		<div className="footer">
			<div className="footer_contents">
				karuta
			</div>
		</div>
	)
};

export default Footer;