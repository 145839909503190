import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Auth from './auth';
import Layout from './pages/Layout';
import Login from './pages/Login';
import Top from './pages/Top';

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route exact path="/login" element={ <Login /> } />
					<Route element={ <Auth /> }>
						<Route element={ <Layout /> }>
							<Route exact path="/" element={ <Top /> } />
						</Route>
					</Route>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
